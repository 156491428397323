import axios from 'axios';

const localStorageItem = 'specialities';
const specialities = JSON.parse(localStorage.getItem(localStorageItem) || '[]');

export default {
    state: {
        specialities
    },
    actions: {
        async fetchSpecialities({commit}) {
            const response = await axios.get('main/medical-specialities')
                .then(({data}) => {
                    return data || {}
                });
            commit('setSpecialities', response.data)
        }
    },
    mutations: {
        setSpecialities(state, data) {
            state.specialities = data;
            localStorage.setItem(localStorageItem, JSON.stringify(state.specialities));
        },
        addBranchOffice(state, data) {
            state.specialities.push(data)
            localStorage.setItem(localStorageItem, JSON.stringify(state.specialities));
        },
    },
}