<template>
  <a
    class="options-item"
    @click.prevent="onClickElement"
  >
    <b-icon :icon="icon"></b-icon>
    <div class="option-content">
      <span>{{ title }}</span>
      <span>{{ body }}</span>
    </div>
  </a>
</template>

<script>
  export default {
    name: "EllipsisDropDownItem",
    props: {
      title: String,
      body: String,
      icon: String,
      to: String | Object,
      event: {
        type: String,
        default: 'clickItem'
      }
    },
    methods: {
      onClickElement() {
        if (!!this.to) {
          this.$router.push(this.to);
        } else {
          this.$emit(this.event);
        }
      }
    }
  }
</script>

<style scoped>

</style>
