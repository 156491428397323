export default{
    namespaced:true,
    state:{
        patient: {},
        company: {},
        studies: [],
        order: {},
    },
    mutations: {
        setOrder(state,val={}){
          state.order = val;
        },
        setPatient(state,val={}){
            state.patient = val;
        },
        setCompany(state, val = {}) {
            state.company = val;
            state.discountGab = 0;
            state.discountLab = 0;
            state.coPay = false;
            state.agreement = false;
            if (!!val.company) {
                state.payInCash = val.pay_in_cash
            } else {
                state.payInCash = true
            }
        },
        setStudies(state,val=[]){
            state.studies = val;
        },
        clearMedicalSpecialties(state) {
            state.studies = [];
            state.company={};
        },
    },
    actions: {
        setMedicalSpecialties(context,medicalspecialties){
            context.commit('setStudies',medicalspecialties.studies);
            context.commit('setCompany', (medicalspecialties.company || {}));
            context.commit('setPatient', medicalspecialties.patient);
        },
        setOrder(context,val){
            context.commit('setOrder',val);
        },
        setPatient(context,val) {
          context.commit('setPatient', val);
        },
        setCompany(context, val) {
            context.commit('setCompany', val);
            context.commit('setStudies', []);
        },
        setStudies(context, val) {
            context.commit('setStudies',val);
        },
        removePatient(context) {
          context.commit('setPatient',{});
        },
        clearMedicalSpecialties(context) {
            context.commit('clearMedicalSpecialties');
        }
    },
    getters: {
        hasCompany: state =>{
            return !!state.company.company;
        },
        hasPatient: state => {
            return !!state.patient.code;
        }
    }

}