import VueAxios from 'vue-axios';
import VueAuth from '@websanova/vue-auth';
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL || '/api';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.common['Accept'] = 'application/json';

export default {
    install(Vue, router) {

        //Instance axios
        Vue.use(VueAxios, axios);
        // Set router
        Vue.router = router;
        //

        // window.print();
        Vue.use(VueAuth, {
            auth: require('@websanova/vue-auth/drivers/auth/basic.js'),
            http: require('@websanova/vue-auth/drivers/http/axios.1.x.js'),
            router: require('@websanova/vue-auth/drivers/router/vue-router.2.x.js'),
            tokenDefaultName: 'lapi_auth_token',
            tokenStore: ['cookie'],
            rolesVar: 'roles',
            authRedirect: {path: '/auth/login'},
            forbiddenRedirect: {path: '/403'},
            notFoundRedirect: {path: '/404'},
            registerData: {url: 'auth/register', method: 'POST', redirect: '/auth/login'},
            loginData: {url: '/login', method: 'POST', redirect: '/', fetchUser: true},
            logoutData: {url: '/logout', method: 'POST', redirect: '/auth/login', makeRequest: true},
            oauth1Data: {url: 'auth/login', method: 'POST'},
            fetchData: {url: '/user', method: 'GET', enabled: true},
            refreshData: {url: '../oauth/token/refresh', method: 'POST', enabled: false, interval: 30},
            impersonateData: {url: 'auth/impersonate', method: 'POST', redirect: '/'}
        });

        axios.interceptors.response.use(response => {
            return response;
        }, error => {
            let response = error.response;
            if (response.status === 403) {
              //  router.push({name:'not-allowed'})
            }

            if (response.status === 500) {
                if(Vue.prototype.$alfalab){
                    Vue.prototype.$alfalab.reportError(error);
                }
               // router.push({name:'server-error'})
            }

            return Promise.reject(error);
        });
    }
}
