import Buefy from 'buefy';

export default {
    install(Vue) {
        Vue.use(Buefy, {
            defaultIconPack: 'fas',
            defaultMonthNames: [
                'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
            ],
            defaultDayNames: [
                'Do', 'Lu', 'Ma', 'Mi', 'Ju', 'Vi', 'Sa',
            ],
            defaultDatepickerYearsRange: [-50, 50],
            defaultFieldLabelPosition: 'on-border',
            defaultInputAutocomplete: 'off',
            defaultModalCanCancel: ['x', 'cancel', 'button'],
        });
    }
}