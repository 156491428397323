export default {
    namespaced: true,
    state: {
        payments: [],
    },
    mutations: {
        addPayment (state, val = {}) {
            state.payments.push(val);
        },
        deletePayment (state, index = null) {
            state.payments.splice(index, 1);
        },
        clearPayments(state) {
            state.payments = [];
        },
        setPayments(state, val){
            state.payments = val;
        }
    },
    actions: {
        addPayment (context, val) {
            context.commit('addPayment', val);
        },
        deletePayment (context, val) {
            context.commit('deletePayment', val);
        },
        clearPayments(context) {
            context.commit('clearPayments');
        },
        setPayments(context, val) {
            context.commit('setPayments', val);
        }
    },
    getters: {

    }
}
