const compressMenu = !! eval(localStorage.getItem('compressMenu'));

export default {
    state: {
        activeSearch: false,
        activeMenu: false,
        showDocumentsModal: false,
        compressMenu
    },
    mutations: {
        activeFieldSearch(state, value = true) {
            state.activeSearch = value;
        },
        activateMenu(state) {
            state.activeMenu = ! state.activeMenu;
        },
        compressedMenu(state) {
            state.compressMenu = ! state.compressMenu;
            localStorage.setItem('compressMenu', state.compressMenu);
        },
        deactivateMenu(state) {
            state.activeMenu = false;
        },
        setShowDocumentsModal(state, val) {
            state.showDocumentsModal = val;
        }
    },
    actions: {
        activeFieldSearch (context, value = true) {
            context.commit('activeFieldSearch', value)
        },
        setShowDocumentsModal(context, value) {
            context.commit('setShowDocumentsModal', value);
        },
        toggleCompressMenu(context){
            context.commit('compressedMenu');
        }
    },
    getters: {
    }
}