import EllipsisDropDown from "../components/utils/EllipsisDropDown";
import EllipsisDropDownItem from "../components/utils/EllipsisDropDownItem";
import VueContentPlaceholders from 'vue-content-placeholders';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import InfiniteLoading from 'vue-infinite-loading';
import httpMixin from "../mixins/http-mixin";

export default {
    install(Vue) {
        Vue.use(EllipsisDropDown);
        Vue.use(EllipsisDropDownItem);
        Vue.mixin(httpMixin);
        Vue.use(InfiniteLoading, { /* options */ });
        Vue.use(VueContentPlaceholders);
        Vue.component('VueCropper', VueCropper);
    }
}