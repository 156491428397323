const defaultSnackBarDuration = 10000;
const defaultToastDuration = 7000;

export default {
    install(Vue, i18n) {
        const app = new Vue();

        Vue.prototype.$loader = {
            loader: null,
            show() {
                if(this.loader === null){
                    this.loader = app.$buefy.loading.open();
                }
            },
            hide() {
                if (this.loader) {
                    this.loader.close();
                    this.loader = null;
                }
            }
        };

        Vue.prototype.$toast = {
            open(message, type = 'is-dark', duration = defaultToastDuration) {
                app.$buefy.toast.open({
                    message: message,
                    type: type,
                    duration
                })
            },
            primary(message, duration = defaultToastDuration) {
                return this.open(message, 'is-primary', duration);
            },
            success(message, duration = defaultToastDuration) {
                return this.open(message, 'is-success', duration);
            },
            info(message, duration = defaultToastDuration) {
                return this.open(message, 'is-info', duration);
            },
            warning(message, duration = defaultToastDuration) {
                return this.open(message, 'is-warning', duration);
            },
            error(message, duration = defaultToastDuration) {
                return this.open(message, 'is-danger', duration);
            }
        };

        Vue.prototype.$snack = {
            open(message, textButton = null, type = 'is-dark', duration = defaultSnackBarDuration) {

                return new Promise((resolve, reject) => {
                    let actionText = !textButton ?i18n.t('common.retry'): textButton;
                    app.$buefy.snackbar.open({
                        message: message,
                        type: type,
                        duration: duration,
                        position: 'is-top',
                        actionText:  actionText,
                        indefinite: duration === 0,
                        onAction: () => {
                            resolve(true);
                        }
                    })
                });

            },
            primary(message, textButton = null,  duration = defaultSnackBarDuration) {
                return this.open(message, textButton, 'is-danger', duration);
            },
            success(message, textButton = null, duration = defaultSnackBarDuration) {
                return this.open(message, textButton, 'is-danger', duration);
            },
            info(message, textButton = null, duration = defaultSnackBarDuration) {
                return this.open(message, textButton, 'is-danger', duration);
            },
            warning(message, textButton = null, duration = defaultSnackBarDuration) {
                return this.open(message, textButton, 'is-danger', duration);
            },
            danger(message, textButton = null, duration = defaultSnackBarDuration) {
                return this.open(message, textButton, 'is-danger', duration);
            }
        };
    }
}
