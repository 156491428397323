<template>
  <div
          class="dropdown-wrap is-right"
          v-click-outside="onClickOutSide"
          :class="{'is-active': toggle}"
  >
    <button class="dropdown-button" @click.prevent="toggle = ! toggle">
      <b-icon icon="ellipsis-h" size="is-small"></b-icon>
    </button>
    <div class="drop-menu">
      <div class="menu-inner">
        <div class="menu-header">
          <h3>{{ title }}</h3>
        </div>
        <div class="options-items">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "EllipsisDropDown",
    data() {
      return {
        toggle: false
      }
    },
    props: {
      title: String,
    },
    methods: {
      onClickOutSide() {
        this.toggle = false;
      }
    }
  }
</script>

<style scoped>

</style>
