const links =  [
    {name: 'user-password', icon: 'user', color: 'is-google', route: {name: 'user-password'}, exact: false, onlySide: false},
    {name: 'pase-medico', icon: 'clinic-medical', color: 'is-youtube', route: {name: 'pase-medico'}, exact: false, onlySide: false},
];

export default {
    state: {
        links: links,
    },
}
