<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
  export default  {
    mounted() {
      let loader = document.getElementById('main-loader');
      setTimeout(() => {
        loader.classList.remove('is-active');
      }, 1000);
    }
  }
</script>