import ClickOutside from 'vue-click-outside';
import Keypress from 'vue-keypress';
import VueCookies from 'vue-cookies'

export default {
    install(Vue) {
        Vue.directive('ClickOutside', ClickOutside);
      //  Vue.component('Keypress', Keypress);
        Vue.use(VueCookies)
    }
}