import Vue from 'vue';
import './registerServiceWorker';
import './assets/scss/app.scss';
import store from './store';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/css/solid.css';
//Plugins
import VueAuth from './plugins/vue-auth';
import App from './App.vue';
import router from './router';
import VueLaravelEcho from './plugins/laravel-echo';
import VueBulmaFrontEnd from './plugins/bulma-frontend';
import VuePrototypes from './plugins/vue-prototypes';
import i18n from './plugins/vue-i18n';
import VueGlobalPlugins from './plugins/vue-global-plugins';
import VueGlobalComponents from './plugins/vue-global-components';
import VueGlobalFilters from './plugins/vue-global-filters';
import VueClick from './plugins/vue-click-outside';
import Laravel from './plugins/laravel';
import LapiTasks from './plugins/lapi-tasks'
import LapiSpecialities from "@/plugins/lapi-specialities";

Vue.router = router;
Vue.use(VueBulmaFrontEnd);
Vue.use(VueClick);
Vue.use(VuePrototypes);
Vue.use(VueGlobalPlugins);
Vue.use(VueGlobalComponents);
Vue.use(VueGlobalFilters, i18n);
Vue.use(LapiTasks);
Vue.use(LapiSpecialities);
Vue.use(VueAuth, router);
Vue.use(VueLaravelEcho, store);
Vue.use(Laravel);

Vue.config.productionTip = false

Vue.config.keyCodes.f7 = 118;
Vue.config.keyCodes.f3 = 114;

let styles = `background:#fff;color:#e97a07;font-size:1.25rem`;
let pjson = require('../package.json');
console.log('%c Alfalab Innovación Tecnológica', styles);
let packageStyles = `background: #fff; color: #e97a07; font-size: 0.8rem;font-weight:bold`;
console.log(`%c ${pjson.name} version: ${pjson.version}`, packageStyles);
console.log('%c ¡Atención nunca compartas la información que puedes visualizar en esta sección!', 'color:red;font-family:system-ui;font-size:1rem;-webkit-text-stroke: 1px black;font-weight:bold');

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
