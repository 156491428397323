export default {
    namespaced : true,
    state:{
        order: {},
    },
    mutations: {
        setOrder(state, val={}){
            state.order = val;
        },
        clearOrder(state){
            state.order = {};
        }
    },
    actions: {
        setOrder(context, val) {
            context.commit('setOrder', val);
        },
        clearOrder(context) {
            context.commit('clearOrder');
        },
    },
    getters: {

    }
}