const key = 'user_branch_office';
const branchOffice = Number(localStorage.getItem(key) || '0');
export default {
    state: {
        branchOffice
    },
    actions: {

    },
    mutations: {
        setBranchOffice(state, value) {
            state.branchOffice = value;
            localStorage.setItem(key, value);
        }
    }
}