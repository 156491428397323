export default {
    methods: {
        getAll(params = {}) {
            return this.fetchData('get', this.apiUrl, {params: params});
        },
        getData(key, params = {}) {
            return this.fetchData('get', `${this.apiUrl}/${key}`, {params: params});
        },
        storeData(params = {}) {
            return this.fetchData('post', this.apiUrl, params);
        },
        updateData(key, params = {}) {
            return this.fetchData('put', `${this.apiUrl}/${key}`, params);
        },
        deleteData(key, params = {}) {
            return this.fetchData('delete', `${this.apiUrl}/${key}`, {params: params});
        },
        storeFile(file, params = {}, apiUrl = null, fileKey = 'file') {
            let data = new FormData();
            data.append(fileKey, file);
            for ( let key in params ) {
                data.append(key, params[key]);
            }
            return this.fetchData('post', apiUrl || this.apiUrl, data);
        },
        storeFiles(params = {}, apiUrl = null, fileKey = 'file') {
            let data = new FormData();
            for (let key in params ) {
                data.append(key, params[key]);
            }
            return this.fetchData('post', apiUrl || this.apiUrl, data);
        },
        fetchData(method, apiUrl, params) {
            this.errors = {};
            this.loading = true;
            return this.$http[method](apiUrl, params)
                .then(({data}) => {
                    this.loading = false;
                    return data;
                }).catch(({response}) => {
                    this.loading = false;
                    let data = response.data;
                    this.errors = data.errors || {};
                    throw response.data;
                });
        },
        labelType(key) {
            return this.errors.hasOwnProperty(key) ? 'is-danger' : '';
        }
    }
}
