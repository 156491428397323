const formatDate = (date) => {
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    let year = date.getFullYear();
    if (month.length < 2) {
        month = '0' + month;
    }
    if (day.length < 2) {
        day = '0' + day;
    }
    return [year, month, day].join('-');
}

const calculateDiscount = (value, price) => {
    return Number(Number((Number(value) * Number(price)).toFixed(2)) / 100);
};

const applyDiscounts = (context, studies) => {
    if(context.state.discountCard){
        const discountLab = context.state.discountCard.desc_lab;
        const discountGab = context.state.discountCard.desc_gab;
        studies.forEach(el => {
            if (el.is_lab && !el.no_promo && discountLab) {
                el.discount = calculateDiscount(discountLab, el.price_show);
                el.price_show -= el.discount;
            }
            if (el.is_gab && !el.no_promo && discountGab) {
                el.discount = calculateDiscount(discountGab, el.price_show);
                el.price_show -= el.discount;
            }
        });
    }
    return studies;
}

export default {
    namespaced : true,
    state:{
        patient: {},
        company: {},
        studies: [],
        priceLoader: false,
        id: null,
        discountCard: {},
        date: formatDate(new Date()),
        vigency: null,
        folio: null,
        paymentForm: '',
        withIva: true,
    },
    mutations: {
        setFolio(state, val=null){
            state.folio = val;
        },
        setVigency(state, val=null){
            state.vigency = val;
        },
        setDate(state, val=null){
            state.date = formatDate(new Date());
        },
        setDiscountCard(state, val= {}) {
            state.discountCard = val;
        },
        setId(state, val=null){
          state.id = val;
        },
        setPatient(state, val = {}) {
            state.patient = val;
        },
        setCompany(state, val = {}) {
            state.company = val;
            state.discountGab = 0;
            state.discountLab = 0;
            state.coPay = false;
            state.agreement = false;
            if (!!val.company) {
                state.payInCash = val.pay_in_cash
            } else {
                state.payInCash = true
            }
        },
        setStudies(state, val = []) {
            state.studies = val;
        },
        addStudy (state, val) {
            state.studies.push(val || {});
        },
        setPaymentForm(state, val) {
            state.paymentForm = val;
        },
        setWithIva(state, val) {
            state.withIva = val;
        },
        clearQuotation(state) {
            state.studies = [];
            state.company = {};
            state.patient = {};
            state.discountCard = {};
            state.date = formatDate(new Date());
            state.id = null;
            state.folio = null;
            state.vigency = null;
            state.paymentForm = '';
            state.withIva = true;
        },
    },
    actions: {
        setQuotation(context, quotation){
           context.commit('setId', quotation.id);
           context.commit('setStudies', quotation.studies);
           context.commit('setCompany', (quotation.company || {}));
           context.commit('setPatient', quotation.patient);
           context.commit('setDate', quotation.date);
           context.commit('setFolio', quotation.folio);
           context.commit('setVigency', quotation.vigency);
           context.commit('setDiscountCard', (quotation.discountCard || {}))
        },
        setDate(context, val) {
            context.commit('setDate', val);
        },
        setFolio(context, val){
            context.commit('setFolio', val);
        },
        setVigency(context, val) {
            context.commit('setVigency', val);
        },
        setDiscountCard(context, val) {
            context.commit('setDiscountCard', val);
        },
        setId(context, val) {
            context.commit('setId', val);
        },
        setPatient(context, val) {
            context.commit('setPatient', val);
        },
        setCompany(context, val) {
            context.commit('setCompany', val);
            context.commit('setStudies', []);
        },
        setStudies(context, val) {
            context.commit('setStudies', val);
        },
        addStudy (context, val) {
            context.commit('addStudy', val);
        },
        setPaymentForm(context, val) {
            context.commit('setPaymentForm', val);
        },
        setWithIva(context, val) {
            context.commit('setWithIva', val);
        },
        clearQuotation(context) {
            context.commit('clearQuotation');
        },
        removePatient (context) {
            context.commit('setPatient', {});
        },
        updatePrices(context, studies){
            let newStudies = context.state.studies.slice();
            //primero fijamos los precios del backend por company y policies

            newStudies.forEach((item, pos) => {
                const obj = studies.find(item_backend => {
                    return item.code === item_backend.code;
                });
                if(obj){
                    item.price_show = obj.price_show;
                    item.is_lab = obj.is_lab;
                    item.is_gab = obj.is_gab;
                    item.no_promo = obj.no_promo;
                    item.price = obj.price;
                    item.delivered_days = obj.delivery_days
                }
            });

            //colocamos descuentos de la tarjeta de descuentos
            newStudies = applyDiscounts(context, newStudies);
            context.commit('setStudies', newStudies);
        },
    },
    getters: {
        hasId: state => {
          return !!state.id;
        },
        hasPatient: state => {
            return !!state.patient.code;
        },
        hasCompany: state => {
            return !!state.company.company;
        },
        hasStudies: state => {
            return !!state.studies.length;
        },
        indications: state => {
            return state.studies && state.studies.length ? state.studies.map(item => {
                return item.indications
            }).join(' ; ') :''
        },
        subTotal: state => {
            return state.studies.reduce((acc, el) => {
                    return Number(el.price_show || 0) + Number(acc)
            }, 0);
        },
        iva: (state, getters) => {
            if(state.withIva){
                return (Number(getters.subTotal) * 16) / 100;
            }
            return 0;
        },
        total: (state, getters) => {
            return getters.subTotal + getters.iva;
        }
    }
}
