export default {
    async install(Vue) {
        Vue.prototype.$alfalab_specialities = {
            loadXray(component, order) {
                console.log('desde plugin', order);
                component.$loader.show();

                return new Promise((resolve, reject) => {
                    component.$http.get(`/medical-specialties/showXRay/${order}`).then(({data}) => {
                        resolve(data.data);
                    }).finally(() => {
                        component.loading = false;
                        component.$loader.hide();
                    });
                }) ;
            }
        }
    }
}