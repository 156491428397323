//import Echo from 'laravel-echo';
//import * as io from 'socket.io-client';
//import axios from 'axios';

export default {
    async install(Vue, store) {
        const app = new Vue();
        //window.io = io;
        //window.Laravel = {};

        // let getCsrfToken = async () => {
        //     return  app.$http.get('csrf-token')
        //         .then(({data}) => {
        //             Laravel.csrfToken = data;
        //         });
        // }

        let csrfCookie = async () => {
            await app.$http.get('../sanctum/csrf-cookie')
        };

        let interval = setInterval(() => {
           // getCsrfToken();
            csrfCookie();
        }, 600000);

        //await getCsrfToken();

        // const echo = new Echo({
        //     broadcaster: 'socket.io',
        //     host: process.env.VUE_APP_SOCKET_URL || 'http://servicios-lapi.site:6001',
        // });
        //
        // store.subscribe((mutation, state) => {
        //     if (mutation.type === 'setLoggedIn') {
        //        // echo.options.auth.headers['X-CSRF-TOKEN'] = app.$cookies.get('CSRF-TOKEN');
        //         echo.connect();
        //     }
        // });
        //
        // Vue.prototype.$Echo = echo;
    }
}
