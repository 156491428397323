import VueI18n from 'vue-i18n';
import messages from '../locales/messages.json';
import Vue from 'vue';
import axios from 'axios';

Vue.use(VueI18n);
const locale = localStorage.getItem('app_locale') || 'es';
axios.defaults.headers.common['X-Locale'] = locale;

export default new VueI18n({
    locale: locale, // set locale
    messages, // set locale messages
})
