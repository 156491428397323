import * as moment from 'moment';

export default {
  install(Vue, i18n) {
    moment.locale(i18n.locale);
    Vue.prototype.$moment = moment;

    Vue.filter('date', (val, format = 'LLL') => {
      const date = moment(val || new Date());

      if (format === 'now') {
        return date.fromNow();
      }

      return date.format(format);
    });

    Vue.filter('currency', (val, currency = '$', digits = 2) => {
      return `${currency}${Number(val || 0).toFixed(digits)}`;
    });

    Vue.filter('age', (val) => {
      return i18n.t('common.age', {age: val});
    });

    Vue.filter('size', (val, unit = 'MB') => {
      if (unit === 'MB') {
        return  `${(Number(val) / 1024 / 1024).toFixed(3)} ${unit}`;
      }

      if (unit === 'KB') {
        return  `${(Number(val) / 1024).toFixed(3)} ${unit}`;
      }
    });
    Vue.filter('ABC', function (val) {
            return  val.charAt(0)

    })

    Vue.filter('uiAvatar', function (val) {
      return 'https://ui-avatars.com/api/?name=' + encodeURI(val) + '&color=E97A07&background=FDE3C7&size=128';
    });

    Vue.filter('month', (val) => {
      const values = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']
      return val.getMonth() < values.length ? values[val.getMonth()] : val.getMonth();
    })

    Vue.filter('monitorLabStatus', (val) => {
      const values = {
        onTime: 'En proceso', completed: 'Completa', 'delayed': 'Atrasada'
      }
      return values[val] || '';
    })
  }
}
