const parseNotification = (data) => {
  return {
    id: data.id,
    data: data.data || {
      ...data
    },
    created_at: data.created_at || new Date().toISOString(),
    read_at: data.read_at || null
  }
};

export default {
  state: {
    notifications: [],
    menuApps: false,
    unread: 0,
    hasNotification: false
  },
  mutations: {
    addNotification(state, data) {
      if (!state.notifications.find(el => el.id === data.id)) {
        state.notifications.unshift(parseNotification(data));

        let audio = new Audio('/sound/notification.mp3');
        audio.play();

        let old = document.title.replace(/ *\([^)]*\) */g, "");

        state.unread = state.notifications.reduce((acc, el) => {
          return !el.read_at ? acc += 1 : acc;
        }, 0);

        document.title = `(${state.unread}) ${old} `;

        state.hasNotification = true;

      }
    },
    pushNotification(state, data) {
      if (!state.notifications.find(el => el.id === data.id)) {
        state.notifications.push(parseNotification(data));
      }
    },
    readNotification(state, id) {
      let notification = state.notifications.find(el => el.id === id);
      if (!notification.read_at) {
        notification.read_at = new Date();
      }
    }
  },
  actions: {
    addNotification(context, data) {
      context.commit('addNotification', data);
    },
    pushNotification(context, data) {
      context.commit('pushNotification', data);
    }
  }
}
