const links =  [
    {name: 'dashboard', icon: 'tachometer-alt', color: 'is-danger', route: {name: 'home'}, auth: ['1'], exact: true, onlySide: true},
    {name: 'order-pos', icon: 'laptop-medical', color: 'is-danger', route: {name: 'order-pos'},auth: ['1'] , exact: true, onlySide: false},
    {name: 'order', icon: 'file-medical', color: 'is-info', route: {name: 'order'},auth: ['1'] , exact: false, onlySide: false},
    {name: 'quotation', icon: 'coins', color: 'is-success', route: {name: 'quotation'}, auth: ['1'], exact: false, onlySide: false},
    /*{name: 'capture', icon: 'flask', color: 'is-success', route: {name: 'capture'}, auth: ['NONONONO'], exact: false, onlySide: false},*/
    /*{name: 'schedule', icon: 'calendar-alt', color: 'is-google', route: {name: 'schedule'}, auth: ['NONONONO'], exact: false, onlySide: false},*/
    {name: 'result', icon: 'file-medical-alt', color: 'is-google', route: {name: 'result'},auth: ['1'] , exact: true, onlySide: false},
    {name: 'io-samples', icon: 'vials', color: 'is-info', route: {name: 'io-samples'}, exact: false, onlySide: false},
    {name: 'reports', icon: 'folder', color: 'red', route: {name: 'reports'}, exact: false, onlySide: false, onlyLapi: true},
    {name: 'invoice', icon: 'file-invoice-dollar', color: 'is-dark', route: {name: 'invoice' , exact: true, onlySide: false}, onlyLapi: true, enableKey: 'cash_close_service_enable'},
    {name:'cash-close', icon: 'clipboard-check', color: 'is-youtube', route: {name: 'cash-close'},auth: ['1'] , exact: false, onlySide: false, onlyLapi: true, enableKey: 'cash_close_service_enable'},
    //{name:'stats', icon: 'chart-line', color: 'is-whatsapp', route: {name: 'stats'}, auth: ['1'], exact: true, onlySide: false},
    {name:'configure', icon: 'print', color: 'is-dark', route: {name: 'configure'}, auth: ['NONONONO'], exact: false, onlySide: false, code:'config-printers'},
    {name:'admin', icon: 'users-cog', color: 'is-dark', route: {name: 'admin'}, auth: ['NONONONO'], exact: false, onlySide: false},
   // {name: 'captureFull', icon: 'flask-vial', color: 'is-purple', route: {name: 'captureFull'}, auth: ['NONONONO'], exact: false, onlySide: false},
   // {name: 'foreign-work', icon: 'truck-medical', color: 'is-aqua', route: {name: 'foreign-work'},auth: ['1'] , exact: true, onlySide: false},
    {name: 'medical-specialties', icon:'eye', color: 'is-magent', route: {name: 'medical-specialties'},auth: ['1.3.2'], exact: true, onlySide: false}
];

const linksGuardia =  [
    {name: 'dashboard', icon: 'tachometer-alt', color: 'is-danger', route: {name: 'home'}, auth: ['1'], exact: true, onlySide: true},
    {name: 'order-pos', icon: 'laptop-medical', color: 'is-danger', route: {name: 'order-pos'},auth: ['1'] , exact: true, onlySide: false},
    {name: 'io-samples', icon: 'vials', color: 'is-info', route: {name: 'io-samples'}, exact: false, onlySide: false},
    {name:'configure', icon: 'print', color: 'is-dark', route: {name: 'configure'}, auth: ['NONONONO'], exact: false, onlySide: false, code:'config-printers'},
    {name:'admin', icon: 'users-cog', color: 'is-dark', route: {name: 'admin'}, auth: ['NONONONO'], exact: false, onlySide: false},
];

export default {
    state: {
        links: links,
        linksGuardiaNacional: linksGuardia,
        menuApps: false,
    },
    mutations: {
        toggleMenuApps(state) {
            state.menuApps = !state.menuApps;
        },
    },
}
