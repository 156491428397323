const DEFAULT_PRICE_LIST = 'MB19';

export default {
    state: {
        priceList: DEFAULT_PRICE_LIST,
        showModalSelectPatient: false,
    },
    mutations: {
        setPriceList(state, value) {
            state.priceList = value;
        },
        setShowModalSelectPatient(state, value){
            state.showModalSelectPatient = value;
        }
    },
    actions: {
        setPriceList({commit}, value) {
            commit('setPriceList', value)
        },
        setShowModalSelectPatient({commit}, value) {
            commit('setShowModalSelectPatient', value)
        }
    }
}
