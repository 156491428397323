const links =  [
    {name: 'pending-orders', icon: 'file', color: 'is-info', route: {name: 'pending-orders'}, exact: false, onlySide: false, onlyLapi: true},
    {name: 'orders-day', icon: 'file-medical', color: 'is-info', route: {name: 'orders-day'}, exact: false, onlySide: false, onlyLapi: false},
];

export default {
    state: {
        links: links,
    },
}
